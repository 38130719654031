.Detail {
  font-size: 12px;
}
.Detail .tabbarTar {
  position: relative;
  margin-bottom: 2.17%;
}
.Detail .tabbarTar .bannerTabbar {
  width: 100%;
}
.Detail .tabbarTar .bannerTabbar img {
  width: 100%;
}
.Detail .wrapper {
  width: 120rem;
  text-align: center;
  margin: 5% auto 0;
}
.Detail .wrapper .contentFrame .title {
  font-size: 3.17rem;
  font-family: PingFang-SC-Medium, PingFang-SC;
  font-weight: 500;
  color: #333333;
  line-height: 4.42rem;
  margin-bottom: 4rem;
}
.Detail .wrapper .contentFrame .title2 {
  font-size: 2rem;
  text-align: center;
}
.Detail .wrapper .contentFrame .text-center {
  font-size: 30px;
  line-height: 6.2rem;
  font-weight: 600;
}
.Detail .wrapper .contentFrame .content {
  font-size: 1.8rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  margin-bottom: 2.33rem;
}
.Detail .wrapper .contentFrame .contentImg {
  text-align: center;
}
.Detail .wrapper .contentFrame .contentImg img {
  width: 50rem;
  margin-bottom: 2.33rem;
}
.Detail .wrapper .contentFrame .contentImg-bor {
  text-align: center;
}
.Detail .wrapper .contentFrame .contentImg-bor img {
  width: 90.8rem;
  margin-bottom: 2.33rem;
}
.Detail .wrapper .contentFrame .borderC {
  border: 3px solid #8d8d8d;
  margin: 3rem 0;
  padding: 1rem;
}
.Detail .wrapper .contentFrame .borderC .personal-resume {
  border: 6px dotted #8d8d8d;
  padding: 2rem;
  text-align: left;
}
.Detail .wrapper .contentFrame .borderC .personal-resume .resumeTitle {
  text-align: center;
  font-size: 3rem;
  margin: 3rem 0;
}
